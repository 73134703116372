.dropzone {
  border: 2px dashed grey;
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.invisible {
  display: none;
}

.react-draggable {
  left: 0;
  top: 0;
}