.layout-splitter {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh !important;
}

.splitter-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 1 0 auto;
  width: 1px;
  background-color: grey;
}

.splitter-layout.main>.layout-splitter {
  width: 1.3rem;
  background-color: transparent;
}

.splitter-layout.main>.layout-splitter:hover {
  width: 1.3rem;
  background-color: transparent;
}

.splitter-layout.main.layout-changing>.layout-splitter {
  width: 1.3rem;
  background-color: transparent;
}

.splitter-layout.main.layout-changing>.layout-splitter:hover {
  width: 1.3rem;
  background-color: transparent;
}

.splitter-handler {
  flex: 0 0 auto;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  border-radius: 50%;
  border: 1px solid #bbb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.splitter-handler span {
  color: grey;
}

.splitter-handler .divider {
  width: 0.3rem;
}

.splitter-layout.main .layout-splitter:hover {
  cursor: col-resize;
  background-color: initial;
}
