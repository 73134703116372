.tabs-menu {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0;
}

.tabs-menu-item {
  float: left;
  margin-right: 20px;
}

.tabs-menu-item a {
  cursor: pointer;
  display: block;
  color: #A9A9A9;
}

.tabs-menu-item:not(.is-active) a:hover,
.tabs-menu-item.is-active a {
  color: #3498DB;
}

.tab-panel {
  padding: 10px 50px;
}